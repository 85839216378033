/**
 * Feature flags
 */

// enum value must match flag key in LaunchDarkly
export enum FeatureFlag {
    ADDITIONAL_BILLING_FIELDS_POSTCODE = 'additional-billing-fields-postcode',
    AI_SITES = 'ai-sites',
    ALLOW_EMAIL_LINKS = 'allow-email-links',
    ALLOW_SMS_LINKS = 'allow-sms-links',
    ATOM_LOYALTY_ENROLLMENT_TOGGLE = 'atom-loyalty-enrollment-toggle',
    // @todo #7225 remove after back-fill completion
    BLOCK_BACKFILL_IMPORTS = 'block-backfill-imports',
    BLOCK_SIGNUP = 'block-signup',
    CAMPAIGNS_V2_OBJECTIVE_BOOKINGS = 'campaigns-v2-objective-bookings',
    CAMPAIGNS_V2_UNSUBSCRIBE_COLUMN = 'campaigns-v2-unsubscribe-column',
    CAMPAIGNS_V2_CUSTOM_CAMPAIGNS = 'campaigns-v2-custom-campaigns',
    COMPANYCAM_INTEGRATION = 'companycam-integration',
    COMPANY_IS_VERIFIED = 'company-is-verified',
    COMPETITOR_INSIGHTS = 'competitor-insights',
    CUSTOMER_MATCH_VERSION = 'customer-match-version',
    FEEDBACK_ROUTING_SETTINGS = 'feedback-routing-settings',
    GET_REPEATS_CAMPAIGN_INSIGHTS = 'get-repeats-campaign-insights',
    GIFT_CAMPAIGN_PHASE_0 = 'gift-campaign-phase-0',
    GOALS = 'goals',
    INVITE_PAST_CUSTOMERS_OPPORTUNITY = 'invite-past-customers-opportunity',
    LOGIN_WITH_GOOGLE = 'login-with-google',
    NEW_CONDITIONAL_PERSON_DELETION = 'new-conditional-person-deletion-logic',
    SNIPPET_MODEL = 'snippet-model',
    ONBOARDING = 'onboarding',
    ONBOARDING_CACHE_MANIPULATION = 'onboarding-cache-manipulation',
    ONBOARDING_INITIAL_REDIRECT = 'onboarding-initial-redirect',
    ONBOARDING_WITH_PHONE_VERIFICATION = 'onboarding-with-phone-verification',
    PAYMENTS_EXPLORE_REVIEWS = 'payments-explore-reviews',
    PAYSTONE_PAYMENTS = 'paystone-payments',
    PEOPLE_SEGMENTS = 'people-segments',
    RECOMMENDATION_LEAD_ALERT = 'recommendation-lead-alert',
    RECOMMENDATIONS = 'recommendations',
    REFERRAL_RATE_BIG_QUERY = 'referral-rate-big-query',
    SPLIT_TEST_APP_CARD = 'split-test-app-card',
    REFERRAL_ENHANCEMENTS = 'split-test-referral-enhancements',
    USE_SUGARWISH_TEST_ACCOUNT_IN_PRODUCTION = 'use-sugarwish-test-account-in-production',
    JOBBER_CUSTOM_FIELD = 'jobber-custom-field',
    SEND_TEST_MESSAGE = 'send-test-message',
    WIN_REPEAT_BUSINESS_CAMPAIGN = 'win-repeat-business-campaign',
    AI_REVIEW_REPLY = 'ai-review-reply',
    GET_REPEAT_BUSINESS_ADVANCED_RULES = 'get-repeat-business-advanced-rules',
    ATOM_ACTIVATION_FLOW = 'atom-activation-flow',
    PRICING_STRUCTURE_PHASE_1 = 'pricing-restructure-phase-1',
    PRICING_STRUCTURE_PHASE_2 = 'pricing-restructure-phase-2',
    ATOM_REFERRALS = 'atom-referrals',
}

export enum BlockSignupCheckResult {
    BLOCK = 'BLOCK',
    NOTIFY = 'NOTIFY',
    ALLOW = 'ALLOW',
}

type CustomerMatchVersionCheckResult = 'v1' | 'v2' | 'dorian' | 'vector_search';

export interface IMLModelConfig {
    version: {
        major: MLModelMajorVersion;
        minor: MLModelMinorVersion;
    };
}

// model major version
export enum MLModelMajorVersion {
    V1 = 'ionian',
    V2 = 'dorian',
    V3 = 'phrygian',
    V4 = 'lydian',
    V5 = 'mixolydian',
    V7 = 'aeolian',
    V8 = 'locrian',
}

// model minor version
export enum MLModelMinorVersion {
    V1 = 'unison',
    V2 = 'minor_second',
    V3 = 'major_second',
    V4 = 'minor_third',
    V5 = 'major_third',
    V6 = 'perfect_fourth',
    V7 = 'tritone',
    V8 = 'perfect_fifth',
    V9 = 'minor_sixth',
    V10 = 'major_sixth',
    V11 = 'minor_seventh',
    V12 = 'major_seventh',
    V13 = 'octave',
    V14 = 'compound_minor_second',
    V15 = 'compound_major_second',
    V16 = 'compound_minor_third',
    V17 = 'compound_major_third',
    V18 = 'compound_perfect_fourth',
    V19 = 'compound_tritone',
    V20 = 'compound_perfect_fifth',
    V21 = 'compound_minor_sixth',
    V22 = 'compound_major_sixth',
    V23 = 'compound_minor_seventh',
    V24 = 'compound_major_seventh',
    V25 = 'double_octave',
}

export interface ReferralEnhancementsCheckResult {
    campaign_template?: string;
    cohort_name: string;
}

/**
 * Feature flag check result types
 */
export interface FeatureFlagCustomCheckResultMap {
    [FeatureFlag.BLOCK_SIGNUP]: BlockSignupCheckResult;
    [FeatureFlag.CUSTOMER_MATCH_VERSION]: CustomerMatchVersionCheckResult;
    [FeatureFlag.SNIPPET_MODEL]: IMLModelConfig;
    [FeatureFlag.REFERRAL_ENHANCEMENTS]: ReferralEnhancementsCheckResult;
}

export type FeatureFlagCheckResult<
    Flag extends FeatureFlag | 'default'
> = Flag extends keyof FeatureFlagCustomCheckResultMap
    ? FeatureFlagCustomCheckResultMap[Flag]
    : boolean;
