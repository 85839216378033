/**
 * @nicejob-library/features interfaces
 */

import { LDClient, LDContext, LDOptions, LDSingleKindContext } from '@launchdarkly/node-server-sdk';
import { Logger } from '@nicejob/nicejob-logger';
import { IAddress } from '@nicejob-library/data-platform-implementation/entities/common';
import { ICompany } from '@nicejob-library/data-platform-implementation/entities/Company';
import { IUserRiskProfile } from '@nicejob-library/data-platform-implementation/entities/User/UserRiskProfile';
import { Request } from 'express';
import { FeatureFlag, FeatureFlagCheckResult } from '../../../common';
import { User } from '../../../types/entities.types';
import { IErrorsModule } from '../../../types/modules.types';

export interface IFeaturesModule {
    projectId: string;
    test_environment: boolean;
    verbose: boolean;
    config: LDOptions;
    client?: LDClient;

    // block-signup flag override
    check(input: {
        // NB: this flag must be excluded from the default overload
        flag: FeatureFlag.BLOCK_SIGNUP;
        custom_fields: {
            input: {
                email: string;
                name?: string;
            };
            risk_profile: IUserRiskProfile;
        };
    }): Promise<FeatureFlagCheckResult<FeatureFlag.BLOCK_SIGNUP>>;

    // @todo #7225 remove after back-fill completion
    check(input: {
        // NB: this flag must be excluded from the default overload
        flag: FeatureFlag.BLOCK_BACKFILL_IMPORTS | FeatureFlag.PRICING_STRUCTURE_PHASE_2;
    }): Promise<FeatureFlagCheckResult<'default'>>;

    // Anonymous override
    check(input: {
        // NB: this flag must be excluded from the default overload
        flag: FeatureFlag.LOGIN_WITH_GOOGLE | FeatureFlag.ONBOARDING;
        custom_fields: {
            ip: string;
        };
    }): Promise<FeatureFlagCheckResult<'default'>>;

    // company-only override
    check(input: {
        // NB: these flags must be excluded from the default overload
        flag:
            | FeatureFlag.ADDITIONAL_BILLING_FIELDS_POSTCODE
            | FeatureFlag.ALLOW_EMAIL_LINKS
            | FeatureFlag.ALLOW_SMS_LINKS
            | FeatureFlag.COMPANY_IS_VERIFIED
            | FeatureFlag.RECOMMENDATIONS
            | FeatureFlag.PAYSTONE_PAYMENTS
            | FeatureFlag.NEW_CONDITIONAL_PERSON_DELETION
            | FeatureFlag.JOBBER_CUSTOM_FIELD
            | FeatureFlag.SEND_TEST_MESSAGE
            | FeatureFlag.USE_SUGARWISH_TEST_ACCOUNT_IN_PRODUCTION
            | FeatureFlag.COMPETITOR_INSIGHTS
            | FeatureFlag.PRICING_STRUCTURE_PHASE_1
            | FeatureFlag.PRICING_STRUCTURE_PHASE_2;
        company: IFeaturesCompany;
    }): Promise<FeatureFlagCheckResult<'default'>>;

    // CUSTOMER_MATCH: Company-only; custom return type
    check(input: {
        // NB: these flags must be excluded from the default overload
        flag: FeatureFlag.CUSTOMER_MATCH_VERSION;
        company: IFeaturesCompany;
    }): Promise<FeatureFlagCheckResult<FeatureFlag.CUSTOMER_MATCH_VERSION>>;

    // machine learning model override
    check(input: {
        // NB: this flag must be excluded from the default overload
        flag: FeatureFlag.SNIPPET_MODEL;
        company: IFeaturesCompany;
    }): Promise<FeatureFlagCheckResult<FeatureFlag.SNIPPET_MODEL>>;

    check(input: {
        // NB: this flag must be excluded from the default overload
        flag: FeatureFlag.REFERRAL_ENHANCEMENTS;
        company: IFeaturesCompany;
    }): Promise<FeatureFlagCheckResult<FeatureFlag.REFERRAL_ENHANCEMENTS>>;

    // default overload
    check(input: {
        // NB: flags with overloads above must be excluded here
        flag: Exclude<
            FeatureFlag,
            | FeatureFlag.ALLOW_EMAIL_LINKS
            | FeatureFlag.ALLOW_SMS_LINKS
            | FeatureFlag.BLOCK_SIGNUP
            | FeatureFlag.COMPANY_IS_VERIFIED
            | FeatureFlag.CUSTOMER_MATCH_VERSION
            | FeatureFlag.LOGIN_WITH_GOOGLE
            | FeatureFlag.PAYSTONE_PAYMENTS
            | FeatureFlag.SNIPPET_MODEL
            | FeatureFlag.ONBOARDING
            | FeatureFlag.RECOMMENDATIONS
            | FeatureFlag.REFERRAL_ENHANCEMENTS
            // @todo #7225 remove after back-fill completion
            | FeatureFlag.BLOCK_BACKFILL_IMPORTS
            | FeatureFlag.USE_SUGARWISH_TEST_ACCOUNT_IN_PRODUCTION
            | FeatureFlag.JOBBER_CUSTOM_FIELD
            | FeatureFlag.SEND_TEST_MESSAGE
            | FeatureFlag.PRICING_STRUCTURE_PHASE_1
            | FeatureFlag.PRICING_STRUCTURE_PHASE_2
        >;
        company: IFeaturesCompany;
        user: IFeaturesUser;
    }): Promise<FeatureFlagCheckResult<'default'>>;

    track(input: { goal: string; company: IFeaturesCompany; user: IFeaturesUser }): Promise<void>;

    hash(ld_context: LDContext): Promise<string>;

    generateClientLDUser(input: {
        company: IFeaturesCompany;
        user: IFeaturesUser;
        admin_switching_user_id: string | null;
    }): Promise<IFeaturesClientLDUSer>;

    generateClientLDSingleKindContext(input: {
        request: Request;
        context_kind: ContextKind;
    }): Promise<IFeaturesClientSingleKindContext>;
}

export interface IFeaturesConstructor {
    projectId?: string;
    verbose?: boolean;
    config?: LDOptions;
    launch_darkly_sdk_key?: string;
}

export type IFeaturesCustomFields = Exclude<LDContext['custom'], undefined>;

export interface IFeaturesClientLDUSer {
    data: LDContext;
    hash: string;
}

export interface IFeaturesClientSingleKindContext {
    data: LDSingleKindContext;
    hash: string;
}
export interface IFeaturesContext {
    errors: IErrorsModule;
    log: Logger;
}

export type IFeaturesCompany = Pick<
    ICompany,
    'id' | 'account_id' | 'address' | 'company_name' | 'email' | 'phone'
>;

export type IFeaturesUser = Pick<
    User,
    'id' | 'first_name' | 'last_name' | 'email' | 'phone' | 'companies' | 'accounts'
> & {
    address: Pick<IAddress, 'country' | 'state' | 'city'> | null;
};

export enum ContextKind {
    USER = 'user',
    DEVICE = 'device',
}

export enum MetricKind {
    ONBOARDING_CLIENT_SUBMIT_PHONE = 'onboarding-client-submit-phone',
    ONBOARDING_CLIENT_CLICK_BUTTON_REGISTER = 'onboarding-client-click-button-register',
}
